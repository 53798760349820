import React from 'react'
import hero from '../images/Illustration.png'
import slide0 from '../images/slide0.jpg'
import slide1 from '../images/slide1.jpg'
import slide4 from '../images/slide4.jpg'
import slide5 from '../images/slide5.jpg'
import {Fade, Roll} from 'react-reveal'

const Hero = () => {
    return (
        <div id='hero' className='max-w-[1200px] px-2 m-auto pt-[6rem] h-screen'>
            <div className='relative flex flex-col md:flex-row'>
                <div className='flex flex-col items-center justify-between w-full md:w-1/2'>
                    <div className='w-full md:w-[35rem]'>
                        <h1 className='text-3xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__slow'>
                            BITCOIN 5000
                            <span
                                className='py-2 text-xl md:text-xl animate__animated animate__fadeInLeft animate__delay-1s animate__slow'>(2018)</span>
                            <br/>
                            NOT just another memecoin!
                        </h1>
                        <p className='py-2 text-lg md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                            BITCOIN 5000, created in 2018, was the first-ever meme coin, predating the term itself!
                            <br/>
                            <br/>
                            IT is the original from which SHIBA INU stole all its ideas!!!
                            <br/>

                        </p>
                    </div>
                </div>
                <Roll duration={4000} className='md:w-1/2'>
                    <div className='pr-[2rem]'>
                        <img src={hero} alt="Hero Image"/>
                    </div>
                </Roll>
            </div>
            <Fade duration={8000}>
            <div className="relative flex flex-row pt-8">
                <div className="basis-1/4">
                    <img src={slide0} alt="Slide Image"/>
                </div>
                <div className="basis-1/4">
                    <img src={slide1} alt="Slide Image"/>
                </div>
                <div className="basis-1/4">
                    <img src={slide4} alt="Slide Image"/>
                </div>
                <div className="basis-1/4">
                    <img src={slide5} alt="Slide Image"/>
                </div>
            </div>
            </Fade>
        </div>
    )
}

export default Hero