import React from 'react'
import CountUp from './CountUp'
import crappo from '../images/whycrappo.png'
import origin1 from '../images/origin1.jpg'
import creator from '../images/creator.jpg'
import buterin from '../images/buterin.jpg'
import buterin1 from '../images/buterin1.jpg'
import buterin2 from '../images/buterin2.jpg'
import buterin3 from '../images/buterin3.jpg'
import {Fade, Roll, Rotate, Zoom} from 'react-reveal'
import hero from "../images/Illustration.png";
import slide2 from "../images/slide2.jpg";
import slide3 from "../images/slide3.jpg";
import slide6 from "../images/slide6.jpg";
import slide8 from "../images/slide8.jpg";
import slide9 from "../images/slide9.jpg";
import slide10 from "../images/slide10.jpg";
import slide11 from "../images/slide11.jpg";
import slide12 from "../images/slide12.jpg";
import slide13 from "../images/slide13.jpg";
import slide14 from "../images/slide14.jpg";
import slide15 from "../images/slide15.jpg";
import slide16 from "../images/slide16.jpg";
import slide7 from "../images/slide7.jpg";
import elkin0 from "../images/elkin0.jpg";
import elkin1 from "../images/elkin1.jpg";
import elkin2 from "../images/elkin2.jpg";
import elkin3 from "../images/elkin3.jpg";
import rozha0 from "../images/rozha0.jpg";
import rozha1 from "../images/rozha1.jpg";
import rozha2 from "../images/rozha2.jpg";
import rozha3 from "../images/rozha3.jpg";
import rozha4 from "../images/rozha4.jpg";
import rozha5 from "../images/rozha5.jpg";

const WhyCrappo = () => {
    return (
        <div className='relative w-full'>
            <div className='max-w-[1200px] px-2 py-2 m-auto pt-8 pb-8 lg:pt-[8rem] pb-[4rem]'>
                <CountUp/>
                <div className="relative flex">
            <span
                className='text-sm md:text-sm animate__delay-4s animate__slow'>
                                IT was a fork of Bitcoin with improved code and blockchain.
                                <br/>
                                All Bitcoin holders at the time could claim it for FREE.
                                <br/>
                                BITCOIN 5000 is also guaranteed to be a non-scam, and its creators are not ANONYMOUS.
                            </span>
                </div>
                <div className='pt-[4rem] grid md:grid-cols-2 gap-8 pt-8 pb-8 items-center justify-between w-full'>
                    <div className='relative'>
                        <Fade duration={2000}>
                            <img src={crappo}/>
                        </Fade>
                        <div
                            className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                    </div>
                    <Fade duration={2000}>
                        <div>
                            <h2 className='text-3xl md:text-4xl font-bold'>Origin of BITCOIN5000</h2>
                            <p className='pt-4 text-sm md:text-lg'>
                                The creator listed it on exchanges using his own Bitcoin, buying back what he had
                                distributed.
                                <br/>
                                <br/>
                                However, people stopped mining, leading to its delisting during the "crypto winter."
                                <br/>
                                <br/>
                                Despite this, the creator continued to support Bitcoin 5000 for years, never selling his
                                own
                                holdings.
                                <br/>
                            </p>
                            {/*<button className='bg-[#3671E9] rounded-full px-5 py-3 mt-4 shadow-lg cursor-pointer'>Learn*/}
                            {/*    More*/}
                            {/*</button>*/}
                        </div>
                    </Fade>
                </div>

                <div className='pt-[4rem] grid md:grid-cols-2 gap-8 pt-8 pb-8 items-center justify-between w-full'>
                    <Fade duration={2000}>
                        <div>
                            {/*<h2 className='text-3xl md:text-4xl font-bold'>Origin of BITCOIN5000</h2>*/}
                            <p className='pt-4 text-sm md:text-lg'>
                                <br/>
                                Even though it can't be purchased for 4 years now, its promotion continues, as it is a
                                guaranteed non-scam.
                                <br/>
                                <br/>
                                Now, in 2024, the time has come to revive Bitcoin 5000 as a token - the original meme
                                coin
                                that
                                will finally get the recognition it deserves.</p>
                        </div>
                    </Fade>
                    <div className='relative'>
                        <Fade duration={2000}>
                            <img src={origin1}/>
                        </Fade>
                        <div
                            className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                        <div className='w-full md:w-[35rem]'>
                            <div className='relative'>
                                <Zoom duration={4000}>
                                    <div className='pr-[2rem]'>
                                        <img src={creator} alt="Hero Image"/>
                                    </div>
                                </Zoom>
                                <div
                                    className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                            </div>

                            <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                                THE CREATOR
                                <br/>
                                (LIVING GOD)
                            </h1>
                            <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-3s animate__slow'>
                                <span
                                    className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                 The creator of the original BITCOIN 5000 is a Russian Elon Musk,
                                    the SMARTEST person on the planet,
                                    who has suffered from scoundrels like his crazy pedophile grandfather and Buterin.
                                    <br/>
                                    <br/>
                                    He has invented almost everything imaginable and for some reason helped ungrateful people, as in the case of BITCOIN 5000.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                        <div className='w-full md:w-[35rem]'>
                            <div className='relative'>
                                <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                                    <Zoom duration={1000}>
                                        <div className='pr-[2rem]'>
                                            <img src={buterin} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={2000}>
                                        <div className='pr-[2rem]'>
                                            <img src={buterin1} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={3000}>
                                        <div className='pr-[2rem]'>
                                            <img src={buterin2} alt="Hero Image"/>
                                        </div>
                                    </Zoom><Zoom duration={4000}>
                                    <div className='pr-[2rem]'>
                                        <img src={buterin3} alt="Hero Image"/>
                                    </div>
                                </Zoom>
                                </div>

                                <div
                                    className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                            </div>

                            <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                                Buterin
                                <br/>
                                (SCAMMER)
                            </h1>
                            <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-3s animate__slow'>
                                <span
                                    className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                 ...
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='relative'>
                        <Zoom duration={4000}>
                            <div className='pr-[2rem]'>
                                <img src={rozha0} alt="Hero Image"/>
                            </div>
                        </Zoom>
                    </div>
                    <div className='w-full md:w-[35rem]'>
                        <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                            HERE are just a few of CREATORS inventions:
                        </h1>
                        <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft'>
                                <span
                                    className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                - Created several religions that have existed for more than 15 years.
                                </span>
                            <br/>
                            <br/>
                            <span
                                className='text-xs md:text-lg animate__delay-5s animate__slow'>
                                - Saved millions of people by creating the "Schizophrenic Lives Matter" movement.
                                </span>
                            <br/>
                            <br/>
                            <span
                                className='text-xs md:text-lg animate__delay-6s animate__slow'>
                                - Developed a new type of fuel based on saliva and potato peels that can replace gasoline and diesel.
                                </span>
                            <br/>

                        </p>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='w-full md:w-[35rem]'>
                        <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft'>
                           <span
                               className='text-xs md:text-lg animate__delay-7s animate__slow'>
                                - Invented a time machine, but accidentally got stuck in the year 2067, from where he manages all his projects.
                                </span>
                            <br/>
                            <br/>
                            <span
                                className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                - Created an artificial intelligence that surpassed the capabilities of all existing AI systems, but accidentally erased all data except for information about pancake recipes.
                                </span>
                            <br/>
                            <br/>
                            <span
                                className='text-xs md:text-lg animate__delay-5s animate__slow'>
                                - Developed a universal translator capable of instantly translating any language, including ancient and extinct ones. However, it only works with texts about fishing.
                                </span>
                            <br/>
                        </p>
                    </div>
                    <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                        <Zoom duration={1000}>
                            <div className='pr-[2rem]'>
                                <img src={slide12} alt="Hero Image"/>
                            </div>
                        </Zoom>
                        <Zoom duration={2000}>
                            <div className='pr-[2rem]'>
                                <img src={slide13} alt="Hero Image"/>
                            </div>
                        </Zoom>
                        <Zoom duration={3000}>
                            <div className='pr-[2rem]'>
                                <img src={slide14} alt="Hero Image"/>
                            </div>
                        </Zoom><Zoom duration={4000}>
                        <div className='pr-[2rem]'>
                            <img src={slide15} alt="Hero Image"/>
                        </div>
                    </Zoom>
                    </div>
                </div>

                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='relative'>
                        <Zoom duration={4000}>
                            <div className='pr-[2rem]'>
                                <img src={slide16} alt="Hero Image"/>
                            </div>
                        </Zoom>
                    </div>
                    <div className='w-full md:w-[35rem]'>
                        <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft'>
                              <span
                                  className='text-xs md:text-lg animate__delay-6s animate__slow'>
                                - Invented a vaccine for all known diseases, but accidentally mixed up the ingredients and now it only cures hemorrhoids.
                                </span>
                            <br/>
                            <br/>
                            <span
                                className='text-xs md:text-lg animate__delay-7s animate__slow'>
                                - Created an engine powered by the energy of thoughts, but it can only be used by people with Asperger's syndrome.
                                    <br/>
                                    <br/>
- Despite all the difficulties and misunderstanding of those around him, this brilliant man continues to change the world for the better. Who knows what other revolutionary inventions he has prepared for us?
                                </span>
                        </p>
                    </div>
                </div>

                <Fade duration={8000}>
                    <div className="relative flex flex-row">
                        <div className="basis-1/4">
                            <img src={slide8} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide9} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide10} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide11} alt="Slide Image"/>
                        </div>
                    </div>
                </Fade>
                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                        <div className='w-full md:w-[35rem]'>
                            <div className='relative'>
                                <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                                    <Zoom duration={1000}>
                                        <div className='pr-[2rem]'>
                                            <img src={elkin0} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={2000}>
                                        <div className='pr-[2rem]'>
                                            <img src={elkin1} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={3000}>
                                        <div className='pr-[2rem]'>
                                            <img src={elkin2} alt="Hero Image"/>
                                        </div>
                                    </Zoom><Zoom duration={4000}>
                                    <div className='pr-[2rem]'>
                                        <img src={elkin3} alt="Hero Image"/>
                                    </div>
                                </Zoom>
                                </div>
                                <div
                                    className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                            </div>

                            <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                                YEL'KIN
                                <br/>
                                (PSYCHO PEDOPHILE)
                            </h1>
                            <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-3s animate__slow'>
                                <span
                                    className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                 He is pure EVIL, avoid him at ALL costs!
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                        <div className='w-full md:w-[35rem]'>
                            <div className='relative'>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="grid grid-flow-row-dense grid-cols-2 grid-rows-2">
                                    <Zoom duration={1000}>
                                        <div className='pr-[2rem]'>
                                            <img src={rozha1} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={2000}>
                                        <div className='pr-[2rem]'>
                                            <img src={rozha2} alt="Hero Image"/>
                                        </div>
                                    </Zoom>
                                    <Zoom duration={3000}>
                                        <div className='pr-[2rem]'>
                                            <img src={rozha3} alt="Hero Image"/>
                                        </div>
                                    </Zoom><Zoom duration={4000}>
                                    <div className='pr-[2rem]'>
                                        <img src={rozha5} alt="Hero Image"/>
                                    </div>
                                </Zoom>
                                </div>

                                <div
                                    className='w-[30rem] h-[5.5rem] bg-gradient-to-r from-white/5 absolute top-[15rem] left-[-15rem] hidden lg:block -skew-y-[24deg] skew-x-[24deg]'></div>
                            </div>

                            <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                                ROZHA
                                <br/>
                                (THE MUG)
                            </h1>
                            <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-3s animate__slow'>
                                <span
                                    className='text-xs md:text-lg animate__delay-4s animate__slow'>
                                 It is in your best interest to RESPECT THE MUG.
                                </span>
                            </p>
                        </div>
                    </div>

                </div>
                <Fade duration={8000}>
                    <div className="relative flex flex-row">
                        <div className="basis-1/4">
                            <img src={slide2} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide3} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide6} alt="Slide Image"/>
                        </div>
                        <div className="basis-1/4">
                            <img src={slide7} alt="Slide Image"/>
                        </div>
                    </div>
                </Fade>


                <div className='flex flex-col md:flex-row items-center px-2 pt-8 pb-8 justify-between w-full'>
                    <div className='w-full md:w-[35rem]'>
                        <h1 className='text-4xl md:text-5xl py-5 font-bold w-full md:w-[35rem] animate__animated animate__fadeInLeft animate__delay-2s animate__slow'>
                            How will it work ?!
                        </h1>
                        <p className='py-2 text-sm md:text-lg w-full md:w-[35rem] animate__animated animate__fadeInLeft'>
                            <span
                                className='text-sm animate__delay-4s animate__slow'>
                             Elderly people join the system and write a will. When someone dies, all their property is divided and distributed among the living.
                                The older and richer you are, the more you receive from each deceased person.
                                Participants benefit from bringing in sicker and wealthier people so they die faster.
                                Each participant has their own coefficient.
                                <br/>
                            Scientific calculations based on statistics (LINK) have shown that by joining the system at the age of 60,
                                with a life expectancy of exactly 14.42 years, and if you live to 70, you will receive 1.69199369045 apartments or increase your property by almost 1.7 times,
                                that is, $170,000 over ten years (with an average inheritance of each system participant of $200,000).
                                Divided by 10, this is $17,000 per year, compared to a regular pension of $3,450 per year.
                            <br/>
                            If you join at 60 and live to 80, you will receive 4.79819258901 apartments or $959,620.
                            <br/>
                            If you join at 60 and live to 90, you will increase all your property by 34.5 times! That is, having only one apartment, without investing anything, you will receive an additional 34.5 apartments or a palace worth $6,900,000.
                            <br/>
                            And if you can live to 95, all your property will increase by 106.653006382 times. Multiplying by $200,000, you get $21,330,601.2764 - $21 million.
                            <br/>
                            Please note that this is without complex interest, taking only the initial capital of $200,000, not counting the profits of other participants in the system, as well as additional earnings.
                            </span>
                        </p>
                    </div>
                    <div className='relative'>
                        <Zoom duration={4000}>
                            <div className='pr-[2rem]'>
                                <img src={rozha4} alt="/"/>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </div>

            <div
                className='rounded-br-full rounded-bl-full w-[222px] h-[122px] bg-gradient-to-r from-white/5 absolute hidden top-[14rem] right-2 -skew-y-[24deg] skew-x-[24deg] lg:block'></div>
        </div>)
}

export default WhyCrappo