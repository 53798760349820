import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import WhyCrappo from "./components/WhyCrappo";
import MatrixRain from './components/MatrixRain';

function App() {
    return (
        <div className="App">
            <Navbar/>
            <Hero/>
            <MatrixRain/>
            <WhyCrappo/>
            <Footer/>
        </div>
    );
}

export default App;
